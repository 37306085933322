var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$parent.modalDetalle.historicos_estados.length > 0)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-success card-outline"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-truck"}),_vm._v(" "+_vm._s(_vm.$parent.modalDetalle.placa)+" ")]),_vm._m(0)]),_c('div',{staticClass:"card-body",staticStyle:{"display":"block"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-hover table-sm text-center",attrs:{"id":"docs"}},[_vm._m(1),_c('tbody',_vm._l((_vm.$parent.modalDetalle
                        .historicos_estados),function(estadosHis){return _c('tr',{key:estadosHis.id},[_c('td',{staticClass:"texte-center"},[_vm._v(" "+_vm._s(estadosHis.fecha_hora)+" ")]),_c('td',{staticClass:"texte-center"},[_c('span',{staticClass:"badge",class:[
                            estadosHis.estado_anterior == 1
                              ? 'badge-secondary'
                              : estadosHis.estado_anterior == 2
                              ? 'badge-success'
                              : estadosHis.estado_anterior == 3
                              ? 'badge-danger'
                              : estadosHis.estado_anterior == 4
                              ? 'bg-olive'
                              : estadosHis.estado_anterior == 5
                              ? 'badge-warning'
                              : estadosHis.estado_anterior == 6
                              ? 'badge-info'
                              : estadosHis.estado_anterior == 7
                              ? 'bg-navy'
                              : estadosHis.estado_anterior == 8
                              ? 'bg-lime'
                              : '',
                          ]},[_vm._v(_vm._s(estadosHis.nEstadoAnt))])]),_c('td',{staticClass:"texte-center"},[_c('span',{staticClass:"badge",class:[
                            estadosHis.estado_nuevo == 1
                              ? 'badge-secondary'
                              : estadosHis.estado_nuevo == 2
                              ? 'badge-success'
                              : estadosHis.estado_nuevo == 3
                              ? 'badge-danger'
                              : estadosHis.estado_nuevo == 4
                              ? 'bg-olive'
                              : estadosHis.estado_nuevo == 5
                              ? 'badge-warning'
                              : estadosHis.estado_nuevo == 6
                              ? 'badge-info'
                              : estadosHis.estado_nuevo == 7
                              ? 'bg-navy'
                              : estadosHis.estado_nuevo == 8
                              ? 'bg-lime'
                              : '',
                          ]},[_vm._v(_vm._s(estadosHis.nEstadoNew))])]),_c('td',{staticClass:"texte-center",domProps:{"textContent":_vm._s(estadosHis.observacion)}}),_c('td',{staticClass:"texte-center"},[_vm._v(_vm._s(estadosHis.user.name))])])}),0)])])])])])])])]):_c('div',{staticClass:"card-body"},[_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Estado Anterior")]),_c('th',[_vm._v("Estado Nuevo")]),_c('th',[_vm._v("Observación")]),_c('th',[_vm._v("Responsable")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-box bg-gradient-warning"},[_c('span',{staticClass:"info-box-icon"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("No tiene Histórico de Estados")])])])
}]

export { render, staticRenderFns }